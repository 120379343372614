import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataShareService } from 'src/app/services/data.service/data-share.service';
import { HttpService } from 'src/app/services/https/http.service';
@Component({
  selector: 'app-student-login',
  templateUrl: './student-login.component.html',
  styleUrls: ['./student-login.component.css']
})
export class StudentLoginComponent implements OnInit {

  constructor(
    private router: Router,
    private httpSv: HttpService,
    private toster: ToastrService,
    private dataShare: DataShareService
  ) { }

  ngOnInit(): void {
    if (sessionStorage.getItem('reportProgress') === 'true') {
      window.location.reload();
    }
    sessionStorage.clear()
  }

  sessionCodeRegex = /^([0-9A-Z]{8})$/;
  emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  firstPage:boolean = true
  secondPage:boolean = false
  isIdentifeirEnabled:boolean = false
  gameSessionCode: any = ''
  studentIdentifier: any = ''
  studentName: any = ''
  userEmail: any = ''
  userPassword: any = ''
  instructorFlag: boolean = false
  adminFlag: boolean = false
  studentFlag: boolean = true;
  email: string = '';

  onInputChange(type:any) {
    if(type === 'sessionCode'){
      this.gameSessionCode = this.gameSessionCode.toUpperCase().trim();
    } else if(type === 'email'){
      this.userEmail = this.userEmail.trim();
    }
  }
  nextStep(){
    if (this.gameSessionCode != '' && this.gameSessionCode) {
      this.httpSv.getGameDetails({gameCode:this.gameSessionCode}).subscribe((res: any) => {
        if(res['status']){
          this.isIdentifeirEnabled = res['data']['is_identifer_enabled'] == 1 ? true : false
          this.firstPage = false;
          this.secondPage = true;
        }
        else{
          this.toster.error('Oops! Game Code does not exists', '', { timeOut: 2000 })
        }
      },(err:any) =>{
        this.toster.error('Oops! Game Code does not exists', '', { timeOut: 2000 })
      })
    }else {
      this.toster.warning('Please fill game code', '', { timeOut: 2000 })
    }
  }
  goNext() {
    if (this.gameSessionCode != '' && this.gameSessionCode) {
      if (this.sessionCodeRegex.test(this.gameSessionCode)) {
        if (this.studentName != '' && this.studentName) {
          if (this.studentIdentifier != '' && this.studentIdentifier) {
            if (this.emailRegex.test(this.studentIdentifier.trim())) {
              const data = {
                name: this.studentName,
                gameSessionCode: this.gameSessionCode.trim(),
                identifier : this.studentIdentifier
              }
              this.httpSv.studentLogin(data).subscribe((res: any) => {
                if (res['status']) {
                  sessionStorage.setItem('playerId', res['result']['player_id'])
                  sessionStorage.setItem('gameType', res['result']['game_type'])
                  sessionStorage.setItem('authToken', res['result']['auth_token'])
                  sessionStorage.setItem('gameId', res['result']['game_id'])
                  sessionStorage.setItem('gameSessionCode', res['result']['session_code'])
                  if (res['result']['game_type'] == 2) {
                    this.router.navigate(['/aboutGame'])
                  } else
                    this.router.navigate(['/about'])
                }
                else {
                  this.toster.error("Oops! Something went wrong", '', { timeOut: 2000 })
                }
              }, (err) => {
                if (err.status == 404) {
                  this.toster.error(err.error['message'], '', { timeOut: 2000 })
                } else {
                  this.toster.warning(err.error['message'], '', { timeOut: 2000 })
                }
              })
            } else {
              this.toster.warning('Please enter a valid email-id', '', { timeOut: 2000 })
            }
          } else {
            this.toster.warning('Please fill the email-id', '', { timeOut: 2000 })
          }
        } else {
          this.toster.warning('Please fill your name', '', { timeOut: 2000 })
        }
      } else {
        this.toster.warning('Only 8 characters alph-numeric game code allowed', '', { timeOut: 2000 })
      }
    } else {
      this.toster.warning('Please fill game code', '', { timeOut: 2000 })
    }
  }

  loginUser() {
    if (this.userEmail != '' && this.userEmail) {
      if (this.emailRegex.test(this.userEmail.trim())) {
        if (this.userPassword != '' && this.userPassword) {
          if (this.adminFlag) {
            const data = {
              email: this.userEmail.trim(),
              password: this.userPassword,
              role_id: 1
            }
            this.httpSv.superAdminLogin(data).subscribe((res: any) => {
              if (res['status']) {
                sessionStorage.setItem('user_id', res['result']['user_id'])
                this.dataShare.userId = res['result']['user_id']
                sessionStorage.setItem('user_name', res['result']['user_name'])
                sessionStorage.setItem('user_img', res['profile'])
                sessionStorage.setItem('auth_token', res['result']['auth_token'])
                this.router.navigate(['/superAdminDashboard'])
              }
              else {
                this.toster.error("Oops! Something went wrong", '', { timeOut: 2000 })
              }
            }, (err) => {
              if (err.status == 404) {
                this.toster.error(err.error['message'], '', { timeOut: 2000 })
              } else {
                this.toster.error(err.error['message'], '', { timeOut: 2000 })
              }
            })
          } else if (this.instructorFlag) {
            const data = {
              email: this.userEmail.trim(),
              password: this.userPassword,
              role_id: 2
            }
            this.httpSv.universityLogin(data).subscribe((res: any) => {
              if (res['status']) {
                sessionStorage.setItem('user_id', res['result']['user_id'])
                sessionStorage.setItem('game_types', res['result']['game_types'])
                sessionStorage.setItem('auth_token', res['result']['auth_token'])
                sessionStorage.setItem('university_code', res['result']['university_code'])
                this.dataShare.game_types = res['result']['game_types'];
                this.router.navigate(['/instructor'])
              }
              else {
                this.toster.error("Oops! Something went wrong", '', { timeOut: 2000 })
              }
            }, (err) => {
              if (err.status == 404) {
                this.toster.error(err.error['message'], '', { timeOut: 2000 })
              } else {
                this.toster.warning(err.error['message'], '', { timeOut: 2000 })
              }
            })
          }
        } else {
          this.toster.warning('Please enter the password', '', { timeOut: 2000 })
        }
      } else {
        this.toster.warning('Please enter a valid email-id', '', { timeOut: 2000 })
      }
    } else {
      this.toster.warning('Please fill the email-id', '', { timeOut: 2000 })
    }

    // this.router.navigate(['/superAdminDashboard'])
    // return;
    // if(this.universityCodeInstructor === 'admin' && this.passwordInstructor === 'admin@esg'){
    //   this.router.navigate(['/superadmin'])
    // }

  }

  handleLoginForm(data: any) {
    switch (data) {
      case 'admin':
        this.adminFlag = true;
        this.instructorFlag = false;
        this.studentFlag = false;
        break;
      case 'instructor':
        this.adminFlag = false;
        this.instructorFlag = true;
        this.studentFlag = false;
        break;
      default:
        this.adminFlag = false;
        this.instructorFlag = false;
        this.studentFlag = true;
    }
  }

}
