<div class="test" style="overflow: hidden;">
    <app-header (messageEmitter)="opendrawer($event)" #childComponentRef></app-header>

    <div *ngIf="!gameInfoFlag" class="gameContainer"   @slideInOut [ngStyle]="!curveBallQueFlag ? {'background': 'url(' + themeBgImageUrl + ') center / cover no-repeat'} : {'display': 'block'}">
        <div *ngIf="!curveBallQueFlag" class="gameSubConatiner" >
                <div class="question-header" *ngIf="!roundStartStatus">
                    <div style="padding-right: 20px;">
                        Round {{roundNumber}}
                    </div>
                    <div style="height: 100%;
                        width: 100%;
                        align-items: center;
                        display: contents;">
                            Theme : {{themeName}}
                        </div>
                    </div>
            <div  class="d-flex justify-content-around align-items-start " [ngClass]="{tableConatainer:!roundStartStatus} ">
                <!-- FOR ESG GAME -->
                <div class="score-bar" *ngIf="!roundStartStatus && isCustomGame == 0">
                    <div class="progress-bar-main-div">
                        <div>
                            <div class="progress-bar-heading">Effort points</div>
                            <div style="background-color: #E3E3E3;border-radius: 10px;">
                                <div class="progress-bar progress-bar-striped progress-bar-animated  qureySelectClass" role="progressbar"
                                    style="background-color: #55A55A;height: 15px;border-radius: 10px;" aria-valuenow="75"
                                    aria-valuemin="0" aria-valuemax="100" id="cashInHandBar"
                                    [style.width]="(cashInHandProgress) + '%'"></div>
                            </div>
                        </div>
                        <div class="progress-persent-txt counter" [@incrementAnimation]="animationState">
                            {{cashInHandValue}}
                        </div>
                    </div>
                    <div class="progress-bar-main-div">
                        <div>
                            <div class="progress-bar-heading">ESG Score</div>
                            <div style="background-color: #E3E3E3;border-radius: 10px;">
                                <div class="progress-bar progress-bar-striped progress-bar-animated  qureySelectClass" role="progressbar"
                                    style="background-color: #55A55A;height: 15px;border-radius: 10px;" aria-valuenow="75"
                                    aria-valuemin="0" aria-valuemax="100" id="cashInHandBar"
                                    [style.width]="(esgScoreProgress) + '%'"></div>
                            </div>
                        </div>
                        <div class="progress-persent-txt counter" [@incrementAnimation]="animationState">
                            {{esgScoreProgress}}
                        </div>
                    </div>
                    <div class="progress-bar-main-div">
                        <div>
                            <div class="progress-bar-heading">Brand Value</div>
                            <div style="background-color: #E3E3E3;border-radius: 10px;">
                                <div class="progress-bar progress-bar-striped progress-bar-animated qureySelectClass" role="progressbar"
                                    style="background-color: #55A55A;height: 15px;border-radius: 10px;" aria-valuenow="75"
                                    aria-valuemin="0" aria-valuemax="100" id="cashInHandBar"
                                    [style.width]="(customerSentimentProgress) + '%'"></div>
                            </div>
                        </div>
                        <div class="progress-persent-txt counter" [@incrementAnimation]="animationState">
                            {{customerSentimentProgress}}
                        </div>
                    </div>
                </div>

                <div class="score-bar" *ngIf="!roundStartStatus && isCustomGame == 1">
                    <div class="progress-bar-main-div" *ngFor="let labels of customFormulaLabels; let i = index">
                        <div>
                          <div class="progress-bar-heading">{{labels.name.toUpperCase()}}</div>
                          <div style="background-color: #E3E3E3; border-radius: 10px;">
                            <div class="progress-bar progress-bar-striped progress-bar-animated qureySelectClass" 
                                 role="progressbar"
                                 style="background-color: #55A55A; height: 15px; border-radius: 10px;" 
                                 aria-valuenow="75"
                                 aria-valuemin="0" 
                                 aria-valuemax="100" 
                                 id="cashInHandBar"
                                 [style.width]="getProgressWidth(progressBarValue[i])">
                            </div>
                          </div>
                        </div>
                        <div class="progress-persent-txt counter" [@incrementAnimation]="animationState" >
                          {{currentValues[i]}}
                        </div>
                      </div>
                      
                </div>
            <!-- <div> -->
                <!-- <div class="round-bgimg round_bgimg" [style.background]="'url(' + themeBgImageUrl + ') center / cover no-repeat'"></div> -->
                <!-- <div class="round-bgimg round_2_bgimg" *ngIf="roundNumber == 2"></div>
                <div class="round-bgimg round_5_bgimg" *ngIf="roundNumber == 3"></div>
                <div class="round-bgimg round_4_bgimg" *ngIf="roundNumber == 4"></div>
                <div class="round-bgimg round_3_bgimg" *ngIf="roundNumber == 5"></div> -->
            <!-- </div> -->
            <div class="main-container" *ngIf="roundStartStatus">
                <div class="intro-round">
                    <div class="intro-main" *ngIf="roundStartStatus">
                        <div>
                            <h2>Round {{roundNumber}}</h2>
                        </div>
                        <!-- <div>
                        <h6>September 2023</h6>
                    </div> -->
                        <div>
                            <h3>Theme : {{themeName}}</h3>
                        </div>
                        <!-- <div class="start-btn" (click)="startRound()"><img style="width: 100%;"
                                src="../../../assets/img/start.svg"></div> -->
                        <div class="start-btn" (click)="startRound()">START</div>
                    </div>
                </div>
            </div>
            <div class="main-container" style="align-items: flex-start;" *ngIf="!roundStartStatus  && isCustomGame == 0 && false">
                <div class="intro-round">
                    <div class="question-conatiner" id="question-conatiner">
                        <div class="question-header">
                            <div style="width: 30%;">
                                Round {{roundNumber}}
                            </div>
                            <div style="height: 100%;
                        width: 100%;
                        align-items: center;
                        display: contents;">
                                Theme : {{themeName}}
                            </div>
                        </div>
                        <div class="question-body">
                            <div class="question-body-round">
                                Question {{allQuestionCount}}/15
                            </div>
                            <div class="question">
                                <!-- {{question}} -->
                                <p *ngFor="let quesPara of question;" style="margin: 0 0 5px" [innerHTML]="makeWordsBold(quesPara)"></p>
                            </div>
                            <div class="options" *ngFor="let option of options" (click)="selectOption(option)"
                                [ngClass]="{selectedOption:selectedOption.option_id==option.option_id} ">
                                <span style="display:flex; flex-direction:column; flex: 1;">
                                    <p *ngFor="let optPara of option.option_text;" style="margin: 0 0 5px" [innerHTML]="makeWordsBold(optPara)"></p>
                                </span>
                                <span style="width: 60px; display: flex; flex-direction: column;     border-left: 2px solid gray;
                            padding-left: 5px;">
                                    <span>Effort</span>
                                    <span>{{option.budget}} Pts</span>
                                </span>
                            </div>
                            <div class="nextQuestion">
                                <img style="float: right; cursor: pointer;" src="../../../assets/img/next-orange.svg" (click)="nextQuestion()">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-container" style="align-items: flex-start;" *ngIf="!roundStartStatus && isCustomGame == 1 && false">
                <div class="intro-round">
                    <div class="question-conatiner" id="question-conatiner">
                        <div class="question-header">
                            <div style="width: 30%;">
                                Round {{roundNumber}}
                            </div>
                            <div style="height: 100%;
                        width: 100%;
                        align-items: center;
                        display: contents;">
                                Theme : {{themeName}}
                            </div>
                        </div>
                        <div class="question-body">
                            <div class="question-body-round">
                                Question {{allQuestionCount}}/15
                            </div>
                            <div class="question">
                                <!-- {{question}} -->
                                <p *ngFor="let quesPara of question;" style="margin: 0 0 5px" [innerHTML]="makeWordsBold(quesPara)"></p>
                            </div>
                            <div class="options" *ngFor="let option of options" (click)="selectOption(option)"
                                [ngClass]="{selectedOption:selectedOption.option_id==option.option_id} ">
                                <span style="display:flex; flex-direction:column; flex: 1;">
                                    <p *ngFor="let optPara of option.option_text;" style="margin: 0 0 5px" [innerHTML]="makeWordsBold(optPara)"></p>
                                </span>
                                <span style="width: 60px; display: flex; flex-direction: column;     border-left: 2px solid gray;
                            padding-left: 5px;">
                                    <span>{{customFormulaName.toUpperCase()}}</span>
                                    <span>{{getOptionValue(option.option_id)}} Pts</span>
                                </span>
                            </div>
                            <div class="nextQuestion">
                                <img style="float: right; cursor: pointer;" src="../../../assets/img/next-orange.svg" (click)="nextQuestion()">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-container" style="align-items: flex-start;" *ngIf="!roundStartStatus  && isCustomGame == 0" @fadeIn>
                <div class="intro-round">
                    <div class="question-conatiner" id="question-conatiner">
                        <div class="question-body">
                            <div class="question-body-round">
                                Question {{allQuestionCount}}/15
                            </div>
                            <div class="question">
                                <!-- {{question}} -->
                                <p *ngFor="let quesPara of question;" style="margin: 0 0 5px" [innerHTML]="makeWordsBold(quesPara)"></p>
                            </div>
                            <div class="options" *ngFor="let option of options" (click)="selectOption(option)"
                                [ngClass]="{selectedOption:selectedOption.option_id==option.option_id} ">
                                <span style="display:flex; flex-direction:column; flex: 1;">
                                    <p *ngFor="let optPara of option.option_text;" style="margin: 0 0 5px" [innerHTML]="makeWordsBold(optPara)"></p>
                                </span>
                                <span style="width: 60px; display: flex; flex-direction: column;     border-left: 2px solid gray;
                            padding-left: 5px;">
                                    <span>Effort</span>
                                    <span>{{option.budget}} Pts</span>
                                </span>
                            </div>
                            <div class="start-btn align-self-end " style="margin-bottom: 3rem;margin-top: 2rem;" (click)="nextQuestion()">
                                NEXT
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-container" style="align-items: flex-start;" *ngIf="!roundStartStatus && isCustomGame == 1" @fadeIn>
                <div class="intro-round">
                    <div class="question-conatiner" id="question-conatiner" style="position: absolute;min-width: 50%;">
                        <div class="question-body"  id="question-body">
                            <div class="question-body-round">
                                Question {{allQuestionCount}}/15
                            </div>
                            <div class="question">
                                <img class="questionGif" src="../../../assets/img/questionMark.gif"/>
                                <!-- {{question}} -->
                                <p *ngFor="let quesPara of question;" style="margin: 0 0 5px" [innerHTML]="makeWordsBold(quesPara)"></p>
                            </div>
                            <div class="options" *ngFor="let option of options" (click)="selectOption(option)"
                                [ngClass]="{selectedOption:selectedOption.option_id==option.option_id} ">
                                <span style="display:flex; flex-direction:column; flex: 1;">
                                    <p *ngFor="let optPara of option.option_text;" style="margin: 0 0 5px" [innerHTML]="makeWordsBold(optPara)"></p>
                                </span>
                                <span class="effortPoints" style="min-width: 10%;max-width:15%; display: flex; flex-direction: column; border-left: 2px solid gray;
                            padding-left: 5px;">
                                    <span style="font-size: 15px;">{{customFormulaName.toUpperCase()}}</span>
                                    <span style="font-weight: 600;">{{getOptionValue(option.option_id)}} Pts</span>
                                </span>
                            </div>
                            <div class="start-btn align-self-end " style="margin-bottom: 3rem;margin-top: 2rem;" (click)="nextQuestion()">
                                NEXT
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div *ngIf="curveBallQueFlag" class="gameSubConatinerCurveBall">
            <!-- FOR ESG GAME -->
            <div style="margin-top: 5rem;" class="score-bar animate-small-to-normal" *ngIf="isCustomGame == 0">
                <div class="progress-bar-main-div">
                    <div>
                        <div class="progress-bar-heading">Effort points</div>
                        <div style="background-color: #E3E3E3;border-radius: 10px;">
                            <div class="progress-bar" role="progressbar"
                                style="background-color: black;height: 15px;border-radius: 10px;" aria-valuenow="75"
                                aria-valuemin="0" aria-valuemax="100" id="cashInHandBar"
                                [style.width]="(cashInHandProgress) + '%'"></div>
                        </div>
                    </div>
                    <div class="progress-persent-txt">
                        {{cashInHandValue}}
                    </div>
                </div>
                <div class="progress-bar-main-div">
                    <div>
                        <div class="progress-bar-heading">ESG Score</div>
                        <div style="background-color: #E3E3E3;border-radius: 10px;">
                            <div class="progress-bar" role="progressbar"
                                style="background-color: black;height: 15px;border-radius: 10px;" aria-valuenow="75"
                                aria-valuemin="0" aria-valuemax="100" id="cashInHandBar"
                                [style.width]="(esgScoreProgress) + '%'"></div>
                        </div>
                    </div>
                    <div class="progress-persent-txt">
                        {{esgScoreProgress}}
                    </div>
                </div>
                <div class="progress-bar-main-div">
                    <div>
                        <div class="progress-bar-heading">Brand Value</div>
                        <div style="background-color: #E3E3E3;border-radius: 10px;">
                            <div class="progress-bar" role="progressbar"
                                style="background-color: black;height: 15px;border-radius: 10px;" aria-valuenow="75"
                                aria-valuemin="0" aria-valuemax="100" id="cashInHandBar"
                                [style.width]="(customerSentimentProgress) + '%'"></div>
                        </div>
                    </div>
                    <div class="progress-persent-txt">
                        {{customerSentimentProgress}}
                    </div>
                </div>
            </div>



             <!-- FOR CUSTOM ESG GAME -->
             <div style="margin-top: 5rem;" class="score-bar animate-small-to-normal" *ngIf="isCustomGame == 1">
                <div class="progress-bar-main-div" *ngFor="let lables of customFormulaLabels;let i = index">
                    <div>
                        <div class="progress-bar-heading">{{lables.name.toUpperCase()}}</div>
                        <div style="background-color: #E3E3E3;border-radius: 10px;">
                            <div class="progress-bar progress-bar-striped progress-bar-animated  qureySelectClass" role="progressbar"
                                style="background-color: black;height: 15px;border-radius: 10px;" aria-valuenow="75"
                                aria-valuemin="0" aria-valuemax="100" id="cashInHandBar"
                                [style.width]="customFormulaValues[i].value>100 ? 100 : customFormulaValues[i].value + '%'"></div>
                        </div>
                    </div>
                    <div class="progress-persent-txt">
                        {{customFormulaValues[i].value}}
                    </div>
                </div>
            </div>
            <div class=" curve-ball-main-div">
                <div style="text-align: center;width: 80%;display: flex; flex-direction: column;" class="visiblity-aminate flex-direction-column"
                    *ngIf="!curveBallQuePopupFlag">
                    <div class="curve_header">CURVE BALL</div>
                    <div class="curve_que_div">
                        <p *ngFor="let quesPara of curveBallQues;" style="margin: 0 0 5px" [innerHTML]="makeWordsBold(quesPara)"></p>
                        <!-- {{curveBallQueData.ques_text}} -->
                    </div>
                    <div *ngIf='curveBallQueData.options[0].option_text != ""'>
                        <div class="curve_option_div"
                            [ngClass]="{curve_selected_option:selectedCurveBallOption.option_id == item.option_id}"
                            *ngFor="let item of curveBallQueData.options; let i=index;" (click)="handleCurveQueOption(item)">
                            <p *ngFor="let optPara of curveBallOptions[i]" style="margin: 0 0 5px" [innerHTML]="makeWordsBold(optPara)"></p>
                        </div>
                    </div>
                    <div class="curveBtn" (click)="handleCurveQueSubmit()" >
                        NEXT
                    </div>
                    <!-- <div>

                        <input type="image" style="cursor: pointer; margin-top: 1rem;" class="curve_ball_next_btn"
                            (click)="handleCurveQueSubmit()" src="../../../assets/img/next-white.svg" alt="">
                    </div> -->
                </div>
                <div style="text-align: center;width: 80%;display:flex;flex-direction: column" *ngIf="curveBallQuePopupFlag"
                    class="slider-aminate d-flex flex-direction-column">
                    <div class="curve_header">CURVE BALL</div>
                    <div class="curve_ball_popup">
                        <p *ngFor="let optPara of curveBallPopup" style="margin: 0 0 5px" [innerHTML]="makeWordsBold(optPara)"></p>
                        <!-- {{selectedCurveBallOption.popup}} -->
                    </div>
                        <div class="curveBtn" (click)="backToQue()" >
                            OK
                        </div>
                        <!-- <input type="image" class="curve_ball_next_btn" style="cursor: pointer;" 
                            src="../../../assets/img/ok-white.svg" alt=""> -->
                </div>
                <div class="curveImage">
                    <img style="width: 18dvw;" src="../../../assets/img/curveBall.gif"/>
                </div>
            </div>
        </div>
    </div>
    <app-about-game *ngIf="gameInfoFlag" [page]="page" (messageEmitter)="close($event)"></app-about-game>
</div>