<div class="login-main-conitainer" id="uniqueId">
    <div class="login-main-sub-conitainer" *ngIf="studentFlag && firstPage">
        <div class="header">
            <!-- <img class="esg-logo" src="../../../assets/img/sentance-line.svg" alt="" style="width:100%;"> -->
            <p>Game</p>
        </div>
        <div class="int-div">
            <div class="text-int-div">Enter Game Code</div>
            <input type="text" placeholder="XXXXXXXX" class="form-control inp-ele" [(ngModel)]="gameSessionCode" (input)="onInputChange('sessionCode')" maxlength="8">
        </div>
        <!-- <div class="int-div">
            <div class="text-int-div">Enter Your Name</div>
            <input type="text" placeholder="Name" class="form-control" class="inp-ele" [(ngModel)]="studentName" (keyup.enter)="goNext()">
        </div> -->
        <div>
            <div class="submitContainer" (click)="nextStep()">
                ENTER
            </div>
            <!-- <input type="image" src="../../../assets/img/submit-button_blue.svg" class="submit-btn" (click)="goNext()" style="width:100%;"> -->
        </div>
        <div style="text-align: center;">
            <div class="orTxt">OR</div>
            <div class="instructor">Login as <p style="margin-left: 5px;" (click)="handleLoginForm('instructor')">Instructor</p><p style="margin: 0 5px;text-decoration: none;"> / </p><p (click)="handleLoginForm('admin')">Admin</p></div>
        </div>
    </div>
    <div class="login-main-sub-conitainer" *ngIf="studentFlag && secondPage">
        <div class="header">
            <!-- <img class="esg-logo" src="../../../assets/img/sentance-line.svg" alt="" style="width:100%;"> -->
            <p>Game</p>
        </div>
        <div class="int-div">
            <div class="text-int-div">Enter Your Name</div>
            <input type="text" placeholder="Name" class="form-control" class="inp-ele" [(ngModel)]="studentName" >
        </div>
        <div class="int-div" *ngIf="isIdentifeirEnabled">
            <div class="text-int-div">Enter Your Identifier(Email Id)</div>
            <input type="text" placeholder="Name" class="form-control" class="inp-ele" [(ngModel)]="studentIdentifier" (keyup.enter)="goNext()">
        </div>
        <div>
            <div class="submitContainer" (click)="goNext()">
                SUBMIT
            </div>
            <!-- <input type="image" src="../../../assets/img/submit-button_blue.svg" class="submit-btn" (click)="goNext()" style="width:100%;"> -->
        </div>
        <div style="text-align: center;">
            <div class="orTxt">OR</div>
            <div class="instructor">Login as <p style="margin-left: 5px;" (click)="handleLoginForm('instructor')">Instructor</p><p style="margin: 0 5px;text-decoration: none;"> / </p><p (click)="handleLoginForm('admin')">Admin</p></div>
        </div>
    </div>
    <div class="login-main-sub-conitainer" *ngIf="instructorFlag||adminFlag">
        <div class="header">
            <!-- <img class="esg-logo" src="../../../assets/img/sentance-line.svg" alt="" style="width:100%;"> -->
            <p>Game</p>
        </div>
        <div class="int-div">
            <div class="text-int-div">Enter Email Id</div>
            <input type="text" placeholder="e.g. you@example.com" class="form-control" class="inp-ele" [(ngModel)]="userEmail" (input)="onInputChange('email')" (keyup.enter)="loginUser()">
        </div>
        <div class="int-div">
            <div class="text-int-div">Password</div>
            <input type="password" placeholder="********" class="form-control" class="inp-ele" [(ngModel)]="userPassword" (keyup.enter)="loginUser()">
        </div>
        <div style="text-align: center;" class="submitContainer"  (click)="loginUser()">
            Submit
            <!-- <input type="image" src="../../../assets/img/submit-button_blue.svg" class="submit-btn" (click)="loginUser()"  style="width:100%;"> -->
        </div>
        <div style="text-align: center;">
            <div class="orTxt">OR</div>
            <div class="instructor" (click)="handleLoginForm('student')">Login as    Student</div>
        </div>
    </div>
    <!-- <button (click)="testFunc('image.png')">capture</button> -->
    
</div>