import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmailStudentSummaryComponent } from '../../student-summary/student-summary.component';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/https/http.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-epg-game-summary',
  templateUrl: './epg-game-summary.component.html',
  styleUrls: ['./epg-game-summary.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class EpgGameSummaryComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private toster: ToastrService,
    private router: Router,
    private httpSv: HttpService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.document.body.style.overflowX = 'hidden';
    this.document.body.style.width = '100vw';
    this.document.body.style.height = '100vh';
    this.session_code = sessionStorage.getItem('gameSessionCode')
    this.player_id = sessionStorage.getItem('playerId')
    if (!this.session_code || !this.player_id) {
      this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      this.router.navigate(['/login'])
    }
    sessionStorage.setItem('epgEnd', '1')
    this.getSummaryData()
  }
  emailRequested: boolean = false
  summaryTableData: any[] = Array.from({ length: 20 }, () => { '' })
  session_code: any
  player_id: any
  summaryData:any
  captureImage() {
    const dialogRef = this.dialog.open(EmailStudentSummaryComponent, {
      maxWidth: '600px',
      minWidth: '450px',
      disableClose: true,
      data: {
        fromEPG: true,
        plyID : this.player_id
      }
    })
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.emailRequested = true
      } else {

      }
    })
  }
  get funName() {
    return ''
  }
  getSummaryData() {
    this.httpSv.getEpgPlayerSummary(this.session_code, this.player_id).subscribe((res: any) => {
      if (res['status']) {
        this.summaryData = res['results']
        this.summaryTableData = res['results'].epg_strategy_values
      } else {
        this.toster.error('Opps! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error('Opps! Something went wrong', '', { timeOut: 2000 })
    })
  }
  getUpstartProfit(profit:any){
    if (profit == 0) return '&nbsp;&nbsp;$0'
    else if(!profit) return ''
    const formattedProfit = Number(profit).toLocaleString('en-US');
    return profit.toString().slice(0,1)=='-'?"- $"+formattedProfit.slice(1):"&nbsp;&nbsp;$"+formattedProfit
  }
}
